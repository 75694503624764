import { Button, Checkbox, Form, Input, Result } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {  businessDetailsAction, sendReferralAction } from '../../actions/business';
import CustomPhoneNumber from '@components/Comman/CustomPhoneNumber/CustomPhoneNumber';

const SendReferralMarketing = ({ isView = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { businessCode, senderCode } = useParams();
  const { isLoading } = useSelector((state: any) => state?.General);
  const [businessData, setBusinessData] = useState<any>();
  const [isSent, setIsSent] = useState<any>(false);
  const userData = useSelector((state: any) => state?.Auth?.user?.user || state?.Auth?.user?.data);
  let stateData = location?.state;

  useEffect(() => {
    const payload = { businessCode };
    if(businessCode) {
      dispatch(
        businessDetailsAction(payload, (resp: any) => {
          if (resp?.status === 200) {
            form.setFieldsValue({
              businessName: resp?.data?.BusinessDetails?.businessName
            });
          }
        })
      );
    }
  }, [businessCode]);

  let updatedRewardList = stateData?.rewards?.length > 0 ?
    stateData?.rewards?.map((item: any) => {
      if (item?.referrerRewardValue) {
        return {
          ...item,
          label: item?.rewardName,
          value: item?.id,
        }
      }
    }) : [];

  const onFinish = (values: any) => {
    values.businessName = stateData?.companyName;
    values.businessCode = businessCode;
    values.referrerCode = senderCode;

    let updatedServiceList = values?.reward?.map((item: any) => {
      let rewardPayload = {
        offerName: item.label,
        offerValue: +(item.referrerRewardValue),
        offerType: item.referrerRewardType,
        id: item.id,
      };
      if (!rewardPayload?.offerType) delete rewardPayload?.offerType;
      if (!rewardPayload?.offerValue) delete rewardPayload?.offerValue

      return rewardPayload;
    });

    values.reward = updatedServiceList;

    dispatch(
      sendReferralAction(values, (resp: any) => {
        if (resp) {
          if(resp?.status === 201) {
            setIsSent(!isSent);
            form.setFieldsValue({
              firstName: '',
              lastName: '',
              email: '',
              mobileNumber: '',
              reward: ''
          });
          }
        }
      })
    );
  };

  const onFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    // You can also display an error message to the user here
  };

  useEffect(() => {
    updatedRewardList = businessData?.business?.rewards?.map((item: any) => {
      return {
        ...item,
        label: item?.rewardName,
        value: item?.id,
      }
    })
  }, [businessData])
  return (
    <div className='authforms'>
      
      {isSent ?
        <div style={{ marginTop: '200px' }}>
          <Result
            status="success"
            title={
              <>
                <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#4CAF50' }}>
                  Referral Sent Successfully!
                </div>
                <div style={{ fontSize: '16px', color: '#555' }}>
                  Thank you for referring a new user! 🎉
                </div>
                <div style={{ fontSize: '16px', color: '#555' }}>
                  Your referral has been successfully submitted. We'll notify you once the referral is processed.
                </div>
                <div style={{ marginTop: '20px', fontSize: '14px', color: '#777' }}>
                  If you have any questions, feel free to contact support.
                </div>
              </>
            }

            extra={[
              <Button
                key="login"
                className="btn btn-primary w-100"
                type="primary"
                onClick={() => {
                  setIsSent(!isSent);
                }}
              >
                Ok
              </Button>
            ]}
          />
        </div>
        :
        <>
          <div className="authHeadings">
            <div className="mr-5" style={{marginRight: '.5em', cursor: 'pointer'}} onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <rect
                  x="0.4"
                  y="1.18168"
                  width="23.2"
                  height="23.2"
                  rx="11.6"
                  stroke="#F47523"
                  strokeWidth="0.8"
                />
                <path
                  d="M14 8.78168L10 12.7817L14 16.7817"
                  stroke="#F47523"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className="textAuthHeading">Refer <span>Us</span></div>
          </div>
          <div className="">
            <div className="">
              <div className="bodycardheading">
                
              </div>
              <div className="">
                <Form
                  className="formControl"
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFailed}
                  initialValues={{
                    businessName: stateData?.companyName,
                  }}
                >
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <Form.Item
                        className="formControlDesign"
                        label="Business Name"
                        name="businessName"
                        rules={[
                          {
                            required: true,
                            message: 'Please select business!',
                          }
                        ]}
                      >
                        <Input disabled placeholder='Business Name' value={userData?.companyName} />
                      </Form.Item>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <Form.Item
                        className="formControlDesign"
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input your First Name!',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter First Name"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <Form.Item
                        className="formControlDesign"
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input your Last Name!',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Last Name"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <Form.Item
                        className="formControlDesign"
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: 'email',
                            message:
                              'Please input a valid email!',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Email"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <CustomPhoneNumber
                        isRequired={true}
                        isToolTip={false}
                        label="Contact Number"
                        name="mobileNumber"
                      />
                    </div>

                    {updatedRewardList?.length > 0 ?
                      <div className="col-lg-12 col-sm-12">
                        <Form.Item
                          name="reward"
                          label="Services"
                          rules={[
                            {
                              required: false,
                              message: 'Please select reward!',
                            },
                          ]}
                        >
                          <Checkbox.Group style={{ width: '100%', padding: '10px 10px 10px 0px' }}>
                            {updatedRewardList?.map((item: any, index: number) => {
                              return item?.label && <Checkbox key={index} value={item} style={{ marginBottom: '10px' }}>
                                <span style={{ marginBottom: '10px' }}>
                                  {item?.label}
                                </span>
                              </Checkbox>
                            })}
                          </Checkbox.Group>
                        </Form.Item>
                      </div>
                      : ""}

                    <div className="col-lg-12 mt-15" style={{ textAlign: 'end', marginTop: '15px' }}>
                      <div className="text-end btn-groups-Forms">
                        <Button loading={isLoading} className='btn btn-primary w-100' type="primary" onClick={() => form.submit()}>
                          Send
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default SendReferralMarketing