// @ts-ignore
import { adminWebUrl } from '../../environment/environment';
import { Button, Col, Form, Input, Result, Row } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { referReferUsAction } from '../../actions/business';
import LabelWithToolTip from '@components/Comman/LabelWithToolTip/LabelWithToolTip';
import { useSelector } from 'react-redux';
import CustomPhoneNumber from '@components/Comman/CustomPhoneNumber/CustomPhoneNumber';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const ReferUS = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state?.General);
    const [tooltipVisible, setTooltipVisible] = useState<any>({
        'business': false,
        'abn': false,
        'email': false,
        'contactPerson': false,
        'position': false,
        'mobile': false,
        'phone': false,
        'address': false,
      });
      const [isSent, setIsSent] = useState(false);


    const onFinish = (values: any) => {
        const payload = {
            companyName: values?.companyName,
            contactName: values?.contactName,
            position: values?.position,
            email: values?.email,
            mobileNumber: values?.mobileNumber,
            contactNumber: values?.contactNumber,
            address: values?.address,
        };

        dispatch(
            referReferUsAction(payload, (resp: any) => {
                if (resp?.status === 201 || resp?.status === 200) {
                    console.log(resp, ".......resp.......")
                    setIsSent(true)
                }
            })
        );

    };

    const onFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        // You can also display an error message to the user here
      };

      const validateMobileInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete'];
        const regex = /^[0-9()+\s]$/; // Allow digits, (), +, and spaces
    
        if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    
  return (
    <> 
    {isSent ? <div style={{ marginTop: '200px' }}>
                <Result
                    status="success"
                    title={
                        <>
                            <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#4CAF50' }}>
                                Registration Successful!
                            </div>
                            <div style={{ fontSize: '16px', color: '#555' }}>
                                Thank you for registering as a Referrer! 🎉
                            </div>
                            <div style={{ fontSize: '16px', color: '#555' }}>
                                Your login credentials have been sent to your email address.
                            </div>
                            <div style={{ marginTop: '20px', fontSize: '14px', color: '#777' }}>
                                Please check your inbox to proceed.
                            </div>
                        </>
                    }
                    extra={[
                        <button className="btn btn-primary" key="login" onClick={()=> window.open(`${adminWebUrl}/login`, '_self')}>
                            LogIn
                        </button>,
                        <button className="btn btn-outline-primary" onClick={()=> navigate(-1)} key="login">
                            Go Back
                        </button>,
                    ]}
                />
            </div> : 

      <div>
          <div className="authforms">
              <div className="authHeadings">
                  <button
                      onClick={() => navigate('/')}
                      className="btn textAuthHeading"
                  >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                      >
                          <rect
                              x="0.4"
                              y="0.4"
                              width="23.2"
                              height="23.2"
                              rx="11.6"
                              stroke="#F47523"
                              stroke-width="0.8"
                          />
                          <path
                              d="M14 8L10 12L14 16"
                              stroke="#F47523"
                              stroke-width="1.25"
                              stroke-linecap="round"
                          />
                      </svg>{' '}
                  </button>
                  <div className="textAuthHeading">
                      Refer <span>ReferUs</span>
                  </div>
              </div>
                  <div style={{marginBottom: '3%', fontWeight: "500"}}>
                      Refer friends to ReferUs and earn rewards! <br />
                      <br />
                      For each Successful referral who purchases a yearly membership, you'll receive $50.
                      <br />
                      For each monthly membership referral, you'll earn $50, paid in two installments of $25 over the first two six-months periods.
                      <br />
                      Rewards apply only to successful referrals who purchase a membership with ReferUs.
                      <br />
                  </div>

              <Row>
                  <Col lg={24} md={24} sm={24}>
                      <Form
                          className="formControl"
                          layout="vertical"
                          form={form}
                          {...layout}
                          onFinish={onFinish}
                          onFinishFailed={onFailed}
                      >
                          <Form.Item
                              name="companyName"
                              className="formControlDesign"
                              label={<LabelWithToolTip titleKey='business' visible={tooltipVisible} setVisible={setTooltipVisible} label='Business Name' title={`If you are part of a business chain or franchise enter the name and then enter your branch . i.e.: Super Cheap Auto - BlackTown`} />}
                          >
                              <Input placeholder="Business Name" />
                          </Form.Item>
                          
                          <Form.Item
                              name="contactName"
                              className="formControlDesign"
                              label={<LabelWithToolTip titleKey='contactPerson' visible={tooltipVisible} setVisible={setTooltipVisible} label='Contact Name' title={`The main person using ReferUs`} />}
                              rules={[
                                  {
                                      required: true,
                                      message: 'Please input contact!',
                                  },
                              ]}
                          >
                              <Input placeholder="Contact Name" />
                          </Form.Item>
                          <Form.Item
                              name="email"
                              className="formControlDesign"
                              label={<LabelWithToolTip titleKey='email' visible={tooltipVisible} setVisible={setTooltipVisible} label='Email Address' title={`Mandatory field as this is how not only we communicate with you but your referrers and referrals`} />}
                              rules={[
                                  {
                                      required: true,
                                      message: 'Please input your email!',
                                  },
                                  {
                                      type: 'email',
                                      message: 'The input is not valid E-mail!',
                                  },
                              ]}
                          >
                              <Input placeholder="Email Address" />
                          </Form.Item>

                          <Form.Item
                            name="position"
                            label={<LabelWithToolTip titleKey='position' visible={tooltipVisible} setVisible={setTooltipVisible} label='Position' title={`Non-mandatory but may come in handy in a future update to complete this field`} />}
                              className="formControlDesign"
                          >
                              <Input placeholder="Position" />
                          </Form.Item>

                          <CustomPhoneNumber 
                            isRequired={true} 
                            isToolTip={true} 
                            label={<LabelWithToolTip titleKey='mobile' visible={tooltipVisible} setVisible={setTooltipVisible} label={<span><span style={{ color: "#ff4d4f", fontSize: '14px' }}>*</span> Contact Number (This can be business or mobile number)</span>} title={`Mandatory field so the system can be opened up on your mobile phone`} />} 
                            name="mobileNumber" 
                            />
                          
                          {/* <CustomPhoneNumber 
                            isRequired={false} 
                            isToolTip={true} 
                            label={<LabelWithToolTip titleKey='phone' visible={tooltipVisible} setVisible={setTooltipVisible} label={<span>Contact Number (Including area code)</span>} title={`A non mandatory field.`} />} 
                            name="contactNumber" 
                            /> */}

                          <Form.Item
                              name="address"
                              label={<LabelWithToolTip titleKey='address' visible={tooltipVisible} setVisible={setTooltipVisible} label='Address' title={`A non mandatory field.`} />}
                              className="formControlDesign"
                          >
                              <Input placeholder="Enter Address" />
                          </Form.Item>

                          <Button
                              className="btn btn-primary w-100"
                              loading={isLoading}
                              type="primary"
                              onClick={() => form.submit()}
                          >
                              Submit
                          </Button>
                      </Form>
                  </Col>
              </Row>
          </div>
      </div>
}
    </>
  );
}

export default ReferUS