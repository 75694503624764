import ApiClient from '../api-client/apiClient';
// @ts-ignore
import { PORT, apiUrl, version, adminWebUrl } from '../environment/environment';


import { notification } from 'antd';
// import { registerSuccess } from '@store/Auth/user';
import { handleIsLoading, handleLoading } from '@store/general';
import { rewardListFailed, rewardListSuccess } from '@store/Business/business';
import { registerSuccess } from '@store/Auth/user';
type NotificationType = 'success' | 'info' | 'warning' | 'error';

function openNotificationWithIcon(type: NotificationType, message: string) {
    notification[type]({
        message: message,
        placement: 'topRight', // You can change the placement if needed
    });
}

// *********** bussiness Details **********************
export const businessDetailsAction = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch(handleIsLoading(true));
        ApiClient.post(`${apiUrl}${PORT}${version}/admin/business-details`, data).then(
            (response: any) => {
                // dispatch({ type: 'ISLOADING', data: false });
                if (response.status === 200 || response.status === 201) {
                    // openNotificationWithIcon('success', response.message);
                    // dispatch(registerSuccess(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else if (response.status === 404) {
                    // openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else {
                    // openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                    return callback(response);
                }
            }
        );
    };
};

// *********** bussiness refer us network **********************
export const getReferUsNetworkBusinesses = (data: any, callback: any) => {
    return (dispatch: any) => {
        delete data.role;
        delete data.admin_id;
        dispatch(handleIsLoading(true))
        ApiClient.get(
            `${apiUrl}${PORT}${version}/admin/get-all-referus-network`,
            data
        ).then(
            (response: any) => {
                if (response.status === 200 || response.status === 201) {
                    // dispatch(businessReferUsgetSuccess(response));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else if (response.status === 404) {
                    // dispatch(businessReferUsgetFailed(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else {
                    // dispatch(businessReferUsgetFailed(response.data));
                    dispatch(handleIsLoading(false));
                }
            }
        );
    };
};


// *********** Rewards List **********************
export const getAllRewards = (data: any, callback: any) => {
    return (dispatch: any) => {
        // const tokenRaw: any = getAccessToken();
        // const token = `Bearer ${tokenRaw}`;
        // const { role } = data;
        // const adminRoute = role === 1 ? "super-admin" : "admin";
        dispatch(handleIsLoading(true))
        ApiClient.get(
            `${apiUrl}${PORT}${version}/admin/reward`,
            {},
            '',
            dispatch
        ).then(
            (response: any) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(rewardListSuccess(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else if (response.status === 404) {
                    dispatch(rewardListFailed(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else {
                    dispatch(rewardListFailed(response.data));
                    dispatch(handleIsLoading(false));
                }
            }
        );
    };
};

export const sendReferralAction = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch(handleIsLoading(true));
        ApiClient.post(
            `${apiUrl}${PORT}${version}/admin/become-referral`,
            data
        ).then((response: any) => {
            if (response.status === 200 || response.status === 201) {
                openNotificationWithIcon('success', response.message);
                dispatch(handleIsLoading(false));
                return callback(response);
            } else if (response.status === 404) {
                openNotificationWithIcon('error', response.message);
                dispatch(handleIsLoading(false));
                return callback(response);
            } else {
                openNotificationWithIcon('error', response.message);
                dispatch(handleIsLoading(false));
            }
        });
    };
};

// *********** Refer referUs action **********************
export const referReferUsAction = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch(handleIsLoading(true));
        ApiClient.post(`${apiUrl}${PORT}${version}/refer/refer-referUs`, data).then(
            (response: any) => {
                // dispatch({ type: 'ISLOADING', data: false });
                if (response.status === 200 || response.status === 201) {
                    openNotificationWithIcon('success', response.message);
                    // dispatch(registerSuccess(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else if (response.status === 404) {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                }
            }
        );
    };
};

// *********** Refer referUs action **********************
export const checkBusinessAvailabilityAction = (data: any, callback: any) => {
    return (dispatch: any) => {
        // dispatch(handleLoading(true));
        ApiClient.post(`${apiUrl}${PORT}${version}/admin/check-business`, data).then(
            (response: any) => {
                // dispatch({ type: 'ISLOADING', data: false });
                if (response.status === 200 || response.status === 201) {
                    // openNotificationWithIcon('success', response.message);
                    // dispatch(registerSuccess(response.data));
                    // dispatch(handleLoading(false));
                    return callback(response);
                } else if (response.status === 404) {
                    openNotificationWithIcon('error', response.message);
                    // dispatch(handleLoading(false));
                    return callback(response);
                } else {
                    openNotificationWithIcon('error', response.message);
                    // dispatch(handleLoading(false));
                }
            }
        );
    };
};


// *********** referrer registration **********************
export const referrerRegistration = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch(handleIsLoading(true));
        ApiClient.post(`${apiUrl}${PORT}${version}/admin/signup-referrer`, data).then(
            async (response: any) => {
                console.log("response ::::::::::", response)
                if (response.status === 200 || response.status === 201) {
                    await openNotificationWithIcon('success', response.message);
                    dispatch(registerSuccess(response.data));
                    dispatch(handleIsLoading(false));
                    // window.open(`${adminWebUrl}/login`, '_self');
                    return callback(response);
                } else if (response.status === 404) {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                }
            }
        );
    };
};
// *********** get all bussiness categories **********************
export const getAllBusinessCategories = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch(handleIsLoading(true));
        // const tokenRaw: any = getAccessToken();
        // const token = `Bearer ${tokenRaw}`;
        ApiClient.get(`${apiUrl}${PORT}${version}/admin/get-all-business-category`, {}, {}, dispatch).then(
            (response: any) => {
                if (response.status === 200 || response.status === 201) {
                    // dispatch(businessCategoryList(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else if (response.status === 404) {
                    // dispatch(businessCategoryList(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else {
                    dispatch(handleIsLoading(false));
                    return callback(response);
                }
            }
        );
    };
};

export const loader = (flag: any) => {
    return (dispatch: any, getState: any) => {
        dispatch({ type: 'ISLOADING', data: flag });
    };
};

export const set_active_tab = (tab: any) => {
    return (dispatch: any, getState: any) => {
        dispatch({ type: 'SET_ACTIVE_TAB', data: tab });
    };
};
