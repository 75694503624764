import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { contactUs } from '../../actions/user';
import { useNavigate } from 'react-router-dom';
import LabelWithToolTip from '@components/Comman/LabelWithToolTip/LabelWithToolTip';
import CustomPhoneNumber from '@components/Comman/CustomPhoneNumber/CustomPhoneNumber';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const ContactUS = ({ handleSubmit, userData }: any) => {
    const navigate = useNavigate();

    const [abn, setAbn] = useState("")
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [tooltipVisible, setTooltipVisible] = useState<any>({
      'business': false,
      'abn': false,
      'email': false,
      'contactPerson': false,
      'position': false,
      'mobile': false,
      'phone': false,
      'address': false,
    });

    const onFinish = (values: any) => {
        const payload = {
            companyName: values?.companyName,
            abn: values?.abn,
            position: values?.position,
            contactName: values?.contactName,
            email: values?.email,
            mobileNumber: values?.mobileNumber,
            contactNumber: values?.contactNumber,
            address: values?.address,
        };

        dispatch(
            contactUs(payload, (resp: any) => {
                if(resp?.status === 201) {
                    navigate('/');
                }
            })
        );
        handleSubmit(payload)

    };
    const onFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        // You can also display an error message to the user here
      };

    const validateAbn = (abn: string) => {
        const abnRegex = /^(\d{11})$/;
        if (!abnRegex.test(abn.replace(/\s+/g, ''))) {
          return {
            validateStatus: 'error' as const,
            errorMsg: 'Invalid ABN',
          };
        }
        return {
          validateStatus: 'success' as const,
          errorMsg: "",
        };
      };
    
      const handleAbnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAbn(e.target.value);
      };

      const validateMobileInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete'];
        const regex = /^[0-9()+\s]$/; // Allow digits, (), +, and spaces
    
        if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };


  return (
    <div>
          <div className='authforms'>
          <div className="authHeadings">
                <button className='btn textAuthHeading' onClick={ () => navigate(-1)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect x="0.4" y="0.4" width="23.2" height="23.2" rx="11.6" stroke="#F47523" stroke-width="0.8" />
                    <path d="M14 8L10 12L14 16" stroke="#F47523" stroke-width="1.25" stroke-linecap="round" />
                </svg> </button>
                <div className="textAuthHeading">Contact <span>Us</span></div>
            </div>

            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Form className='formControl'
                        layout="vertical"
                        form={form}
                        {...layout}
                        onFinish={onFinish}
                        onFinishFailed={onFailed}
                    >
                        <Form.Item 
                            name="companyName" 
                            className='formControlDesign' 
                            label={<LabelWithToolTip titleKey='business' visible={tooltipVisible} setVisible={setTooltipVisible} label='Business Name' title={`A mandatory field as the company name is important throughout the entire system. If you are part of a business chain or franchise enter the name and then enter your branch . i.e.: Super Cheap Auto - BlackTown`} />}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Business name!',
                                }
                            ]}
                        >
                            <Input placeholder='Business Name' />
                        </Form.Item>
                        <Form.Item 
                            name="abn" 
                            className='formControlDesign'
                            label={<LabelWithToolTip titleKey='abn' visible={tooltipVisible} setVisible={setTooltipVisible} label='ABN' title={`A non-mandatory field. This is a business identification in Australia`} />}
                        >
                            <Input placeholder='ABN' value={abn} onChange={handleAbnChange} />
                        </Form.Item>
                        <Form.Item 
                            name="contactName" 
                            className='formControlDesign'
                            label={<LabelWithToolTip titleKey='contactPerson' visible={tooltipVisible} setVisible={setTooltipVisible} label='Contact' title={`The main person using ReferUs`} />}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input contact!',
                                }
                            ]}
                            >
                            <Input placeholder='Contact' />
                             
                        </Form.Item>
                        <Form.Item 
                            name="position" 
                            className='formControlDesign'
                            label={<LabelWithToolTip titleKey='position' visible={tooltipVisible} setVisible={setTooltipVisible} label='Position' title={`Non-mandatory but may come in handy in a future update to complete this field`} />}
                            >
                            <Input placeholder='Position' />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            className='formControlDesign'
                            label={<LabelWithToolTip titleKey='email' visible={tooltipVisible} setVisible={setTooltipVisible} label='Email Address' title={`Mandatory field as this is how we communicate with you.`} />}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                            ]}
                        >
                            <Input placeholder='Email Address' />
                        </Form.Item>
                        <CustomPhoneNumber 
                          isRequired={true} 
                          isToolTip={true} 
                          label={<LabelWithToolTip titleKey='mobile' visible={tooltipVisible} setVisible={setTooltipVisible} label={<span><span style={{ color: "#ff4d4f", fontSize: '14px' }}>*</span> Contact Number (This can be business or mobile number)</span>} title={`Mandatory field so the system can contact you.`} />} 
                          name="mobileNumber" 
                          />
                        
                        {/* <CustomPhoneNumber
                          isRequired={false}
                          isToolTip={true}
                          label={<LabelWithToolTip titleKey='phone' visible={tooltipVisible} setVisible={setTooltipVisible} label={<span>Contact Number (Including area code)</span>} title={`A non mandatory field.`} />}
                          name="contactNumber"
                        /> */}
             

                        <Form.Item 
                          name="address" 
                          className='formControlDesign'
                          label={<LabelWithToolTip titleKey='address' visible={tooltipVisible} setVisible={setTooltipVisible} label='Address' title={`A non mandatory field.`} />}
                          >
                            <Input placeholder='Enter Address' />
                        </Form.Item>

                        <Button className='btn btn-primary w-100' type="primary" onClick={() => form.submit()}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default ContactUS